<template>
  <div class="container">
    <v-expansion-panels>

      <v-expansion-panel class="mb-4 transparent">
        <v-expansion-panel-header class="black--text font-weight-bold">
          КЛУБ В МОСКВЕ
          <template v-slot:actions>
            <v-icon color="red">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="container">
          <span class="font-weight-bold">НАШ АДРЕС</span><br>
          <span>Москва, Байкальская улица, 42/14к1</span><br>
          <span class="font-weight-bold">ТЕЛЕФОН</span><br>
          <span>8-966-120-40-06</span><br>

          <div class="my-2">
            <span class="font-italic font-weight-medium">Клуб работает:</span><br>
            С понедельника по пятницу 15:00 - 22:30.<br>
            По субботам 10:00-18:00.<br>
            Воскресенье - выходной.<br>
          </div>
          <div class="">
            <span class="font-italic font-weight-medium">Как добраться:</span><br>
            Ближайшая станция метро "Щёлковская".
            От метро "Щёлковская" ходят автобусы №68, №3 и №171
            Пешком от метро путь займет 15 мин.
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel class="mb-4 transparent">
        <v-expansion-panel-header class="black--text font-weight-bold">
          КЛУБ В ДОЛГОПРУДНОМ
          <template v-slot:actions>
            <v-icon color="red">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="container">
          <span class="font-weight-bold">НАШ АДРЕС</span><br>
          <span>Долгопрудный, площадь Собина, 1к4</span><br>
          <span class="font-weight-bold">ТЕЛЕФОН</span><br>
          <span>8-495-960-65-12</span><br>

          <div class="my-2">
            <span class="font-italic font-weight-medium">Клуб работает:</span><br>
            С понедельника по пятницу 18:00 - 22:30.<br>
            По субботам 13:00-14:00.<br>
            Воскресенье - выходной.<br>
          </div>
          <div class="">
            <span class="font-italic font-weight-medium">Как добраться:</span><br>
            Клуб расположен в административном центре города,
            От метро "Речной вокзал" ходит маршрутка и автобус №368,
            От метро "Алтуфьево" можно добраться через мкр. "Северный" на маршрутке №465.
            Пешком от ж-д станции "Долгопрудная" путь займет 5 мин.<br>
            <span class="font-italic">Возле клуба есть много парковочных мест.</span>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>

    <div class="mx-4">
      <span class="font-weight-bold">Контактная информация:</span><br>
      Райвич Михаил Львович - rml904@yandex.ru <br>
      Морозов Сергей Владимирович - morozov2004@gmail.com <br><br>
      <span class="font-weight-bold">Расписание занятий вы можете найти на
        <a href="https://www.ilmma.ru/schedule">сайте клуба</a></span>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
